<template>
  <c-box
    flex-grow="1"
    min-width="0"
  >
    <c-flex
      width="100%"
      align-items="center"
      margin-bottom="20px"
      gap="48px"
    >
      <c-button
        margin-top="17.5px"
        variant="ghost"
        display="flex"
        align-items="center"
        gap="1rem"
        @click="$router.back()"
      >
        <c-image
          w="24px"
          h="24px"
          object-fit="cover"
          :src="require('@/assets/icon-chevron-left.svg')"
        />
        <c-text
          font-size="16px"
          color="primary.400"
          font-weight="500"
        >
          Kembali
        </c-text>
      </c-button>

      <BreadcrumbPath
        :paths="[
          {
            label: 'Manajemen Nilai Gizi',
            href: { name:'admin.glossary' },
          },
          {
            label: isEditPage ? 'Edit Nilai Gizi' : 'Tambah Nilai Gizi',
            isCurrent: true
          },
        ]"
      />
    </c-flex>

    <c-box
      width="100%"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '30px 80px']"
      :min-height="['unset', '74vh']"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <c-box
        max-width="800px"
        width="100%"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
        >
          {{ isEditPage ? 'Edit Nilai Gizi' : 'Tambah Nilai Gizi' }}
        </BaseText>

        <c-box
          as="form"
          margin-top="70px"
          width="100%"
        >
          <BaseInputText
            v-model="data.foodName"
            label="Nama Makanan"
            placeholder="Masukkan Nama Makanan"
            full-width
            is-required
            :is-invalid="isInvalidField($v.data.foodName)"
            :invalid-text="parseErrors('Nama Makanan', $v.data.foodName)"
            @blur="$v.data.foodName.$touch"
          />

          <BaseInputAutocomplete
            v-model="data.foodGroup"
            label="Golongan Makanan"
            placeholder="Masukkan Golongan Makanan"
            full-widthf
            is-required
            :options="optionGroups"
            :is-invalid="isInvalidField($v.data.foodGroup)"
            :invalid-text="parseErrors('Golongan Makanan', $v.data.foodGroup)"
            @blur="$v.data.foodGroup.$touch"
          />

          <BaseInputTextarea
            v-if="data.foodGroup?.toUpperCase() === 'ONE-DISH MEAL'"
            v-model="data.foodIngredient"
            label="Komposisi (Per Porsi)"
            placeholder="Masukkan Komposisi (Per Porsi)"
            full-width
            is-required
            :is-invalid="isInvalidField($v.data.foodIngredient)"
            :invalid-text="parseErrors('Golongan Makanan', $v.data.foodIngredient)"
            @blur="$v.data.foodIngredient.$touch"
          />

          <BaseInputAutocomplete
            v-model="data.basicFoodIngredient"
            label="Nama Bahan Dasar Makanan"
            placeholder="Masukkan Nama Bahan Dasar Makanan"
            full-width
            is-required
            :can-manual-input="false"
            :options="optionBasicIngredients"
            :is-invalid="isInvalidField($v.data.basicFoodIngredient)"
            :invalid-text="parseErrors('Nama Bahan Dasar Makanan', $v.data.basicFoodIngredient)"
            @blur="$v.data.basicFoodIngredient.$touch"
          />

          <c-flex gap="32px">
            <BaseInputText
              v-model="data.servingSizePerPortion.servingSizePerPortion"
              label="Takaran Saji/Porsi"
              placeholder="Masukkan Takaran Saji"
              full-width
              is-required
              :is-invalid="isInvalidField($v.data.servingSizePerPortion.servingSizePerPortion)"
              :invalid-text="parseErrors('Takaran Saji', $v.data.servingSizePerPortion.servingSizePerPortion)"
              input-right-addon-without-margin
              @blur="$v.data.servingSizePerPortion.servingSizePerPortion.$touch"
              @focus="selectAllText($event, data?.servingSizePerPortion?.servingSizePerPortion)"
            >
              <template #input-right-addon-element>
                <c-button
                  height="100%"
                  background-color="transparent"
                  font-size="18px"
                  font-weight="500"
                  @click="isOpenModalUnit = 'servingSizePerPortion'"
                >
                  {{ data.servingSizePerPortion.servingSizeUnitPerPortion }}
                  <c-box margin-left="10px">
                    <inline-svg
                      :src="require('@/assets/icons/icon-arrow-down.svg')"
                      height="15"
                      width="15"
                    />
                  </c-box>
                </c-button>
              </template>
            </BaseInputText>
            <BaseInputText
              v-model="data.householdMeasurementPerPortion.householdMeasurementPerPortion"
              label="Ukuran Rumah Tangga/Porsi"
              placeholder="Masukkan Ukuran Rumah Tangga"
              full-width
              is-required
              :is-invalid="isInvalidField($v.data.householdMeasurementPerPortion.householdMeasurementPerPortion)"
              :invalid-text="parseErrors('Ukuran Rumah Tangga', $v.data.householdMeasurementPerPortion.householdMeasurementPerPortion)"
              input-right-addon-without-margin
              @blur="$v.data.householdMeasurementPerPortion.householdMeasurementPerPortion.$touch"
              @focus="selectAllText($event, data?.householdMeasurementPerPortion?.householdMeasurementPerPortion)"
            >
              <template #input-right-addon-element>
                <c-button
                  height="100%"
                  background-color="transparent"
                  font-size="18px"
                  font-weight="500"
                  @click="isOpenModalUnit = 'householdMeasurementPerPortion'"
                >
                  {{ data.householdMeasurementPerPortion.householdMeasurementUnitPerPortion }}
                  <c-box margin-left="10px">
                    <inline-svg
                      :src="require('@/assets/icons/icon-arrow-down.svg')"
                      height="15"
                      width="15"
                    />
                  </c-box>
                </c-button>
              </template>
            </BaseInputText>
          </c-flex>

          <c-flex gap="32px">
            <BaseInputText
              v-model="data.servingSize.servingSize"
              label="Takaran Saji"
              placeholder="Masukkan Takaran Saji"
              full-width
              is-required
              :is-invalid="isInvalidField($v.data.servingSize.servingSize)"
              :invalid-text="parseErrors('Takaran Saji', $v.data.servingSize.servingSize)"
              input-right-addon-without-margin
              @blur="$v.data.servingSize.servingSize.$touch"
              @focus="selectAllText($event, data?.servingSize?.servingSize)"
            >
              <template #input-right-addon-element>
                <c-button
                  height="100%"
                  background-color="transparent"
                  font-size="18px"
                  font-weight="500"
                  @click="isOpenModalUnit = 'servingSize'"
                >
                  {{ data.servingSize.servingSizeUnit }}
                  <c-box margin-left="10px">
                    <inline-svg
                      :src="require('@/assets/icons/icon-arrow-down.svg')"
                      height="15"
                      width="15"
                    />
                  </c-box>
                </c-button>
              </template>
            </BaseInputText>
            <BaseInputText
              v-model="data.householdMeasurement.householdMeasurement"
              label="Ukuran Rumah Tangga"
              placeholder="Masukkan Ukuran Rumah Tangga"
              full-width
              is-required
              :is-invalid="isInvalidField($v.data.householdMeasurement.householdMeasurement)"
              :invalid-text="parseErrors('Ukuran Rumah Tangga', $v.data.householdMeasurement.householdMeasurement)"
              input-right-addon-without-margin
              @blur="$v.data.householdMeasurement.householdMeasurement.$touch"
              @focus="selectAllText($event, data?.householdMeasurement?.householdMeasurement)"
            >
              <template #input-right-addon-element>
                <c-button
                  height="100%"
                  background-color="transparent"
                  font-size="18px"
                  font-weight="500"
                  @click="isOpenModalUnit = 'householdMeasurement'"
                >
                  {{ data.householdMeasurement.householdMeasurementUnit }}
                  <c-box margin-left="10px">
                    <inline-svg
                      :src="require('@/assets/icons/icon-arrow-down.svg')"
                      height="15"
                      width="15"
                    />
                  </c-box>
                </c-button>
              </template>
            </BaseInputText>
          </c-flex>

          <BaseInputPhotos
            label="Gambar Makanan"
            :photos="data.photoUrl"
            is-required
            :is-invalid="isInvalidField($v.data.photoUrl)"
            :invalid-text="parseErrors('Gambar Makanan', $v.data.photoUrl)"
            @update:photos="(newValue) => data.photoUrl = newValue"
            @blur="$v.data.photoUrl.$touch"
          />

          <c-box margin="30px 0 16px 0">
            <BaseText
              size-mobile="18px"
              size-desktop="20px"
              color="primary.400"
            >
              Informasi Nilai Gizi
            </BaseText>
            <BaseDivider />
          </c-box>

          <BaseInputText
            v-model="data.nutritionContent.calories.amount"
            label="Energi"
            placeholder="Masukkan Energi"
            full-width
            is-required
            :is-invalid="isInvalidField($v.data.nutritionContent.calories.amount)"
            :invalid-text="parseErrors('Energi', $v.data.nutritionContent.calories.amount)"
            :input-right-addon="data.nutritionContent?.calories?.unit"
            @blur="$v.data.nutritionContent.calories.amount.$touch"
            @focus="selectAllText($event, data?.nutritionContent?.calories?.amount)"
          />

          <BaseInputText
            v-model="data.nutritionContent.protein.amount"
            label="Protein"
            placeholder="Masukkan Protein"
            full-width
            is-required
            :is-invalid="isInvalidField($v.data.nutritionContent.protein.amount)"
            :invalid-text="parseErrors('Protein', $v.data.nutritionContent.protein.amount)"
            :input-right-addon="data.nutritionContent.protein.unit"
            @blur="$v.data.nutritionContent.protein.amount.$touch"
            @focus="selectAllText($event, data?.nutritionContent?.protein?.amount)"
          />

          <BaseInputText
            v-model="data.nutritionContent.fat.fat.amount"
            label="Lemak"
            placeholder="Masukkan Lemak"
            full-width
            is-required
            :is-invalid="isInvalidField($v.data.nutritionContent.fat.fat.amount)"
            :invalid-text="parseErrors('Lemak', $v.data.nutritionContent.fat.fat.amount)"
            :input-right-addon="data.nutritionContent.fat.fat.unit"
            @blur="$v.data.nutritionContent.fat.fat.amount.$touch"
            @focus="selectAllText($event, data?.nutritionContent?.fat?.fat?.amount)"
          />

          <TestFieldArray v-slot="{ push, remove }">
            <c-box
              v-for="(item, i) in data?.nutritionContent?.fat?.sub || []"
              :key="i"
              margin-top="16px"
              :_first="{ marginTop: '0px' }"
            >
              <c-flex
                gap="32px"
                align-items="start"
              >
                <BaseInputAutocomplete
                  :value="item.name"
                  placeholder="Pilih Turunan"
                  full-width
                  is-required
                  :options="Object.values(parseNutrition.listFatSubs) || []"
                  :is-invalid="isInvalidField($v.data.nutritionContent.fat.sub.$each[i].name)"
                  :invalid-text="parseErrors('Turunan', $v.data.nutritionContent.fat.sub.$each[i].name)"
                  @input="(value) => {
                    item.name = value
                    item.unit = parseNutrition?.listFatSubs?.[value]?.unit
                  }"
                  @blur="$v.data.nutritionContent.fat.sub.$each[i].name.$touch"
                />
                <BaseInputText
                  v-model="item.amount"
                  placeholder="Jumlah"
                  full-width
                  :is-required="false"
                  :input-right-addon="item?.unit"
                  :is-invalid="isInvalidField($v.data.nutritionContent.fat.sub.$each[i].amount)"
                  :invalid-text="parseErrors('Jumlah', $v.data.nutritionContent.fat.sub.$each[i].amount)"
                  @blur="$v.data.nutritionContent.fat.sub.$each[i].amount.$touch"
                  @focus="selectAllText($event, item?.amount)"
                />

                <c-button
                  margin-top="10px"
                  background-color="transparent"
                  @click="remove(data.nutritionContent.fat.sub, i)"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-trash.svg')"
                    height="38px"
                    width="38px"
                    fill="#D32737"
                  />
                </c-button>
              </c-flex>
            </c-box>

            <c-flex
              justify-content="center"
            >
              <BaseButton
                margin="auto"
                mt="-15px"
                size="small"
                border-radius="1000px"
                variant="outlined"
                width="100%"
                :right-svg-icon="require('@/assets/icons/icon-circle-plus.svg')"
                right-svg-icon-color="#008C81"
                @click.prevent="push(data.nutritionContent.fat.sub, {
                  name: '',
                  amount: 0,
                  unit: '',
                })"
              >
                Tambah Turunan
              </BaseButton>
            </c-flex>
          </TestFieldArray>

          <c-box margin-top="30px">
            <BaseInputText
              v-model="data.nutritionContent.carbohydrate.carbohydrate.amount"
              label="Karbohidrat"
              placeholder="Masukkan Karbohidrat"
              full-width
              is-required
              :is-invalid="isInvalidField($v.data.nutritionContent.carbohydrate.carbohydrate.amount)"
              :invalid-text="parseErrors('Karbohidrat', $v.data.nutritionContent.carbohydrate.carbohydrate.amount)"
              :input-right-addon="data?.nutritionContent?.carbohydrate?.carbohydrate?.unit"
              @blur="$v.data.nutritionContent.carbohydrate.carbohydrate.amount.$touch"
              @focus="selectAllText($event, data?.nutritionContent?.carbohydrate?.carbohydrate?.amount)"
            />
          </c-box>

          <TestFieldArray v-slot="{ push, remove }">
            <c-box
              v-for="(item, i) in data?.nutritionContent?.carbohydrate?.sub || []"
              :key="i"
              margin-top="16px"
              :_first="{ marginTop: '0px' }"
            >
              <c-flex
                gap="32px"
                align-items="start"
              >
                <BaseInputAutocomplete
                  :value="item.name"
                  placeholder="Pilih Turunan"
                  full-width
                  is-required
                  :options="Object.values(parseNutrition.listCarbohydrateSubs) || []"
                  :is-invalid="isInvalidField($v.data.nutritionContent.carbohydrate.sub.$each[i].name)"
                  :invalid-text="parseErrors('Turunan', $v.data.nutritionContent.carbohydrate.sub.$each[i].name)"
                  @input="(value) => {
                    item.name = value
                    item.unit = parseNutrition?.listCarbohydrateSubs?.[value]?.unit
                  }"
                  @blur="$v.data.nutritionContent.carbohydrate.sub.$each[i].name.$touch"
                />
                <BaseInputText
                  v-model="item.amount"
                  placeholder="Jumlah"
                  full-width
                  :is-required="false"
                  :input-right-addon="item?.unit"
                  :is-invalid="isInvalidField($v.data.nutritionContent.carbohydrate.sub.$each[i].amount)"
                  :invalid-text="parseErrors('Jumlah', $v.data.nutritionContent.carbohydrate.sub.$each[i].amount)"
                  @blur="$v.data.nutritionContent.carbohydrate.sub.$each[i].amount.$touch"
                  @focus="selectAllText($event, item?.amount)"
                />

                <c-button
                  margin-top="10px"
                  background-color="transparent"
                  @click="remove(data.nutritionContent.carbohydrate.sub, i)"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-trash.svg')"
                    height="38px"
                    width="38px"
                    fill="#D32737"
                  />
                </c-button>
              </c-flex>
            </c-box>

            <c-flex
              justify-content="center"
            >
              <BaseButton
                margin="auto"
                mt="-15px"
                size="small"
                border-radius="1000px"
                variant="outlined"
                width="100%"
                :right-svg-icon="require('@/assets/icons/icon-circle-plus.svg')"
                right-svg-icon-color="#008C81"
                @click.prevent="push(data.nutritionContent.carbohydrate.sub, {
                  name: '',
                  amount: 0,
                  unit: '',
                })"
              >
                Tambah Turunan
              </BaseButton>
            </c-flex>
          </TestFieldArray>

          <c-box margin-top="30px">
            <BaseInputText
              v-model="data.nutritionContent.cholesterol.amount"
              label="Kolesterol"
              placeholder="Masukkan Kolesterol"
              full-width
              is-required
              :is-invalid="isInvalidField($v.data.nutritionContent.cholesterol.amount)"
              :invalid-text="parseErrors('Kolesterol', $v.data.nutritionContent.cholesterol.amount)"
              :input-right-addon="data.nutritionContent.cholesterol.unit"
              @blur="$v.data.nutritionContent.cholesterol.amount.$touch"
              @focus="selectAllText($event, data?.nutritionContent?.cholesterol?.amount)"
            />
          </c-box>

          <BaseInputText
            v-model="data.nutritionContent.natrium.amount"
            label="Natrium (garam)"
            placeholder="Masukkan Natrium (garam)"
            full-width
            is-required
            :is-invalid="isInvalidField($v.data.nutritionContent.natrium.amount)"
            :invalid-text="parseErrors('Natrium (garam)', $v.data.nutritionContent.natrium.amount)"
            :input-right-addon="data?.nutritionContent?.natrium?.unit"
            @blur="$v.data.nutritionContent.natrium.amount.$touch"
            @focus="selectAllText($event, data?.nutritionContent?.natrium?.amount)"
          />

          <BaseInputText
            v-model="data.nutritionContent.iron.amount"
            label="Zat Besi"
            placeholder="Masukkan Zat Besi"
            full-width
            is-required
            :is-invalid="isInvalidField($v.data.nutritionContent.iron.amount)"
            :invalid-text="parseErrors('Zat Besi', $v.data.nutritionContent.iron.amount)"
            :input-right-addon="data.nutritionContent.iron.unit"
            @blur="$v.data.nutritionContent.iron.amount.$touch"
            @focus="selectAllText($event, data?.nutritionContent?.iron?.amount)"
          />

          <TestFieldArray v-slot="{ push, remove }">
            <c-box
              v-for="(item, i) in data?.nutritionContent?.additonalNutritionContent || []"
              :key="i"
              margin-top="16px"
              :_first="{ marginTop: '0px' }"
            >
              <c-flex
                gap="32px"
                align-items="start"
              >
                <BaseInputAutocomplete
                  :value="item.name"
                  placeholder="Masukkan Nama Gizi"
                  full-width
                  is-required
                  :options="Object.values(parseNutrition.listOptionalNutritions) || []"
                  :is-invalid="isInvalidField($v.data.nutritionContent.additonalNutritionContent.$each[i].name)"
                  :invalid-text="parseErrors('Nama Gizi', $v.data.nutritionContent.additonalNutritionContent.$each[i].name)"
                  @input="(value) => {
                    item.name = value
                    item.unit = parseNutrition?.listOptionalNutritions?.[value]?.unit
                  }"
                  @blur="$v.data.nutritionContent.additonalNutritionContent.$each[i].name.$touch"
                />
                <BaseInputText
                  v-model="item.amount"
                  placeholder="Masukkan Nilai Gizi"
                  full-width
                  :is-required="false"
                  :input-right-addon="item.unit"
                  :is-invalid="isInvalidField($v.data.nutritionContent.additonalNutritionContent.$each[i].amount)"
                  :invalid-text="parseErrors('Nilai Gizi', $v.data.nutritionContent.additonalNutritionContent.$each[i].amount)"
                  @blur="$v.data.nutritionContent.additonalNutritionContent.$each[i].amount.$touch"
                  @focus="selectAllText($event, item?.amount)"
                />

                <c-button
                  margin-top="10px"
                  background-color="transparent"
                  @click="remove(data.nutritionContent.additonalNutritionContent, i)"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-trash.svg')"
                    height="38px"
                    width="38px"
                    fill="#D32737"
                  />
                </c-button>
              </c-flex>
            </c-box>

            <c-flex
              justify-content="center"
            >
              <BaseButton
                margin="auto"
                size="large"
                border-radius="1000px"
                variant="outlined"
                width="100%"
                :right-svg-icon="require('@/assets/icons/icon-circle-plus.svg')"
                right-svg-icon-color="#008C81"
                @click.prevent="push(data.nutritionContent.additonalNutritionContent, {
                  name: '',
                  amount: 0,
                  unit: '',
                })"
              >
                Tambah Nilai Gizi Opsional
              </BaseButton>
            </c-flex>
          </TestFieldArray>

          <c-box margin="30px 0 16px 0">
            <BaseText
              size-mobile="18px"
              size-desktop="20px"
              color="primary.400"
            >
              Zat Gizi Dominan
            </BaseText>
            <BaseDivider />
          </c-box>

          <c-text
            font-size="16px"
            font-weight="400"
            padding="0 0 4px 8px"
            :color="isInvalidField($v.data.mainNutritionContent)
              ? '#D32737'
              : !!mainNutritionContentSingleText
                ? 'primary.400'
                : 'black'"
          >
            Zat Gizi Dominan
            <c-text
              as="span"
              color="danger.400"
              margin-left="4px"
            >
              *
            </c-text>
          </c-text>

          <c-button
            border-radius="8px"
            width="100%"
            height="62px"
            border="1px solid #E5E7EB"
            background-color="white"
            font-weight="500"
            font-size="18px"
            :color="!!mainNutritionContentSingleText ? 'primary.400' : '#ACB8C8'"
            justify-content="flex-start"
            padding="0 20px"
            text-align="left"
            style="text-wrap: pretty;"
            @click="isOpenModalZatGiziDominan = true"
          >
            {{ mainNutritionContentSingleText ? mainNutritionContentSingleText : 'Masukkan Zat Gizi Dominan' }}
          </c-button>
          <c-box padding-top="4px">
            <c-text
              as="span"
              color="#D32737"
            >
              {{ isInvalidField($v.data.mainNutritionContent) ? "● Zat Gizi Dominan tidak boleh kosong" : '' }}
            </c-text>
          </c-box>

          <c-box margin="40px 0 16px 0">
            <BaseText
              size-mobile="18px"
              size-desktop="20px"
              color="primary.400"
            >
              Lainnya <c-text
                as="span"
                font-weight="400"
                font-size="14px"
                color="neutral.darkGray"
              >
                (Opsional)
              </c-text>
            </BaseText>
            <BaseDivider />
          </c-box>
          <BaseInputAutocomplete
            v-model="data.other.cuisine"
            label="Makanan Khas"
            placeholder="Masukkan Makanan Khas"
            full-width
            can-manual-input
            :options="optionCuisines"
            @trigger-change="(_, isValueAlreadyExist) => {
              data.isNewCuisine = isValueAlreadyExist ? '0' : '1'
            }"
            @blur="$v.data.foodGroup.$touch"
          />

          <BaseInputText
            v-model="data.other.tag"
            label="Tag Lainnya"
            placeholder="Masukkan Tag Lainnya"
            full-width
            @blur="$v.data.foodName.$touch"
          />

          <c-flex
            margin="auto"
            max-width="600px"
            mt="28px"
            justify-content="space-between"
            gap="20px"
          >
            <BaseButton
              size="large"
              border-radius="1000px"
              width="100%"
              variant="outlined"
              :disabled="isSubmitting"
              @click="cancelEditOrAdd"
            >
              Batal
            </BaseButton>
            <BaseButton
              size="large"
              border-radius="1000px"
              width="100%"
              :disabled="$v.$invalid || !$v.$anyDirty"
              :is-loading="isSubmitting"
              @click="isOpenModalConfirmationEditSchema = true"
            >
              {{ isEditPage ? 'Simpan' : 'Tambah' }}
            </BaseButton>
          </c-flex>
        </c-box>
      </c-box>
    </c-box>

    <!-- MODAL CONFIRMATION EDIT/ADD DATA-->
    <BaseModal
      :is-open="!!isOpenModalConfirmationEditSchema"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            {{ isEditPage
              ? `Apakah anda yakin ingin melakukan perubahan data nilai gizi “${data?.foodName}” ?`
              : 'Apakah anda yakin data yang anda isikan sudah benar?' }}
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalConfirmationEditSchema = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              @click="onSubmit"
            >
              <!--              :is-loading="isSubmitting"-->
              Simpan
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>

    <!-- MODAL SUCCESS EDIT/ADD DATA -->
    <BaseModal
      :is-open="isOpenModalSuccessEditOrAdd"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/success-image.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            {{ isEditPage
              ? 'Data nilai gizi berhasil diubah!'
              : 'Nilai gizi berhasil ditambahkan' }}
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="$router.push({ name: 'admin.glossary' })"
          >
            Oke
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>

    <!-- MODAL CANCEL EDIT/ADD DATA -->
    <BaseModal
      :is-open="isOpenModalCancelEditOrAdd"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Apakah anda yakin ingin membatalkan pengisian Nilai Gizi?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalCancelEditOrAdd = false"
            >
              Kembali
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-trash.svg')"
              right-svg-icon-color="white"
              color="danger"
              rounded="1000px"
              width="100%"
              @click="$router.back()"
            >
              Ya
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>

    <!-- MODAL DROPDOWN TAKARAN -->
    <BaseModal
      :is-open="Boolean(isOpenModalUnit)"
      :close-on-overlay-click="false"
      :with-button-close="false"
      :has-header="false"
    >
      <template #body>
        <c-accordion>
          <c-accordion-item border-top-width="0">
            <c-accordion-header>
              <c-box
                flex="1"
                text-align="left"
                font-weight="500"
                font-size="18px"
                color="primary.400"
                padding="8px"
              >
                {{ ['servingSize','servingSizePerPortion'].includes(isOpenModalUnit) ? 'Takaran Saji' : 'Ukuran Rumah Tangga' }}
              </c-box>
              <c-accordion-icon />
            </c-accordion-header>
            <c-accordion-panel
              padding="4px 0"
              height="50vmin"
              overflow="auto"
            >
              <c-radio-button-group
                v-model="selectedUnit"
                display="flex"
                flex-direction="column"
              >
                <CustomRadio
                  v-for="item in ['servingSize','servingSizePerPortion'].includes(isOpenModalUnit)
                    ? optionServingSizeUnit || []
                    : ['householdMeasurement','householdMeasurementPerPortion'].includes(isOpenModalUnit)
                      ? optionHouseholdMeasurement || []
                      : [] "
                  :key="item.id"
                  size="lg"
                  :value="item.id"
                  variant-color="primary"
                >
                  {{ item.name }}
                </CustomRadio>
              </c-radio-button-group>
            </c-accordion-panel>
          </c-accordion-item>
        </c-accordion>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="cancelChangUnit"
            >
              Batal
            </BaseButton>
            <BaseButton
              color="primary"
              rounded="1000px"
              width="100%"
              :disabled="!selectedUnit"
              @click="changeUnit"
            >
              Terapkan
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>

    <!-- MODAL DROPDOWN ZAT GIZI DOMINAN -->
    <BaseModal
      :is-open="isOpenModalZatGiziDominan"
      :close-on-overlay-click="false"
      :with-button-close="false"
      :has-header="false"
      size="xl"
    >
      <template #body>
        <c-accordion allow-toggle>
          <c-accordion-item border-top-width="0">
            <c-accordion-header>
              <c-box
                flex="1"
                text-align="left"
                font-weight="500"
                font-size="18px"
                color="primary.400"
                padding="8px"
              >
                Zat Gizi Dominan
              </c-box>
              <c-accordion-icon />
            </c-accordion-header>
            <c-accordion-panel padding="8px 16px">
              <Draggable
                v-chakra="{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: '8px',
                }"
                :list="list"
                v-bind="dragOptions"
              >
                <c-flex
                  v-for="(element, idx) in list"
                  :key="element.name"
                  background-color="primary.50"
                  padding="10px 16px"
                  align-items="center"
                  border-radius="calc(infinity * 1px)"
                  gap="6px"
                >
                  {{ idx+1 }}. {{ element.value }}
                  <c-box
                    as="span"
                    display="inline-block"
                    margin-left="auto"
                  >
                    <inline-svg
                      :src="require('@/assets/icons/icon-drag-indicator.svg')"
                      height="22"
                      width="22"
                      fill="#008C81"
                    />
                  </c-box>
                </c-flex>
              </Draggable>
            </c-accordion-panel>
          </c-accordion-item>
        </c-accordion>
        <c-flex
          display="flex"
          flex-direction="column"
          height="50vmin"
          overflow="auto"
        >
          <CustomCheckbox
            v-for="checkedItem in Object.keys(optionMainNutrition)"
            :key="checkedItem"
            :is-checked="optionMainNutrition[checkedItem].checked"
            @change="(isChecked) => checkedMainNutrition(isChecked, checkedItem)"
          >
            <c-input
              v-if="optionMainNutrition[checkedItem]._new"
              v-model="optionMainNutrition[checkedItem].value"
              margin-right="16px"
              font-size="18px"
              font-weight="500"
              @click.stop
            />
            <c-text
              v-else
              as="span"
            >
              {{ optionMainNutrition[checkedItem].value }}
            </c-text>
          </CustomCheckbox>

          <c-flex
            justify-content="center"
            padding="0 16px 10px 16px"
          >
            <BaseButton
              margin="auto"
              size="small"
              border-radius="1000px"
              variant="outlined"
              width="100%"
              margin-top="16px"
              :right-svg-icon="require('@/assets/icons/icon-plus.svg')"
              right-svg-icon-color="#008C81"
              @click.prevent="() => {
                optionMainNutrition = {
                  ...optionMainNutrition,
                  [`mainNutrition${Object.keys(optionMainNutrition).length + 1}`]: {
                    id: Math.random(),
                    _new: true,
                    value: '',
                    checked: false,
                  },
                }
              }"
            >
              Tambah Zat Gizi Dominan
            </BaseButton>
          </c-flex>
        </c-flex>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="cancelChangeMainNutrition"
            >
              Batal
            </BaseButton>
            <BaseButton
              color="primary"
              rounded="1000px"
              width="100%"
              @click="changeMainNutrition"
            >
              Terapkan
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import generalMixin from '@/utils/general-mixins'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BaseText from '@/components/elements/base-text.vue'
import { CBox, CFlex, CImage, CText, CButton, CAccordion, CAccordionItem, CAccordionHeader, CRadioButtonGroup, CAccordionPanel, CAccordionIcon } from '@chakra-ui/vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseInputText from '@/components/elements/base-input-text.vue'
import { required, minValue, requiredIf } from 'vuelidate/lib/validators'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import BaseInputPhotos from '@/components/elements/base-input-photos.vue'
import { isDataEmpty } from '@/utils/is-data-empty'
import { reqReferral } from '@/requests/dietela-api/referral'
import imageCompression from 'browser-image-compression'
import { parseErrorCatch } from '@/utils/parse-error-catch'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import TestFieldArray from '@/views/profile/detail/test-field-array.vue'
import CustomRadio from '@/views/admin/glossary/_widgets/custom-radio.vue'
import Draggable from 'vuedraggable'
import CustomCheckbox from '@/views/admin/glossary/_widgets/custom-checkbox.vue'
import BaseInputAutocomplete from '@/components/elements/base-input-autocomplete.vue'
import {
  reqMealGlossaries_create, reqMealGlossaries_edit,
  reqMealGlossaries_getDetail,
  reqMealGlossaries_getListOptionsBasicIngredient,
} from '@/requests/dietela-api/admin/other'
import BaseInputTextarea from '@/components/elements/base-input-textarea.vue'

export default {
  name: 'ManagementCoachingChallengePage',
  components: {
    BaseInputPhotos,
    BaseInputTextarea,
    BaseInputAutocomplete,
    CustomCheckbox,
    CustomRadio,
    TestFieldArray,
    BaseDivider,
    CText,
    BaseModal,
    CImage,
    CButton,
    BaseInputText,
    BaseButton,
    BaseText,
    BreadcrumbPath,
    CBox,
    CFlex,
    Draggable,
    CAccordion,
    CAccordionItem,
    CAccordionHeader,
    CRadioButtonGroup,
    CAccordionPanel,
    CAccordionIcon,
  },
  mixins: [generalMixin],
  data() {
    return {
      checkedItems: {},
      list: [],
      selectedUnit: null,
      isSubmitting: false,
      isOpenModalConfirmationEditSchema: false,
      isOpenModalZatGiziDominan: false,
      isOpenModalSuccessEditOrAdd: false,
      isOpenModalCancelEditOrAdd: false,

      isOpenModalUnit: false,
      optionBasicIngredients: [],
      optionGroups: [],
      optionCuisines: [],
      optionHouseholdMeasurement: [],
      optionServingSizeUnit: [],
      optionMainNutrition: {},
      listNutritions: [],

      //   ====================================
      data: {
        foodName: '',
        foodGroup: '',
        foodIngredient: '',
        basicFoodIngredient: '',
        servingSizePerPortion: {
          servingSizePerPortion: 0,
          servingSizeUnitPerPortion: '',
        },
        householdMeasurementPerPortion: {
          householdMeasurementPerPortion: 0,
          householdMeasurementUnitPerPortion: '',
        },
        servingSize: {
          servingSize: 0,
          servingSizeUnit: '',
        },
        householdMeasurement: {
          householdMeasurement: 0,
          householdMeasurementUnit: '',
        },
        photoUrl: [''],
        nutritionContent: {
          calories: {
            amount: 0,
            unit: '',
          },
          protein: {
            amount: 0,
            unit: '',
          },
          cholesterol: {
            amount: 0,
            unit: '',
          },
          fat: {
            fat: {
              amount: 0,
              unit: '',
            },
            sub: [],
          },
          carbohydrate: {
            carbohydrate: {
              amount: 0,
              unit: '',
            },
            sub: [],
          },
          natrium: {
            amount: 0,
            unit: '',
          },
          iron: {
            amount: 0,
            unit: '',
          },
          additonalNutritionContent: [],
        },
        mainNutritionContent: [],
        other: {
          cuisine: '',
          tag: '',
        },
        isNewCuisine: 0,
        isNewMainNutrition: 0,
      },
    }
  },
  computed: {
    glossaryId() {
      return this.$route.params.glossaryId
    },
    isEditPage() {
      return this.$route.name === 'admin.glossaries-edit'
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    parseNutrition() {
      return this.listNutritions.reduce((acc, curr) => {
        if (curr.required === 0
            && curr.carbohydrateSub === 0
            && curr.fatSub === 0
        ) {
          acc.listOptionalNutritions = {
            ...acc.listOptionalNutritions,
            [curr.name]: {
              ...curr,
              id: curr?.name,
            },
          }
        }

        if (curr.fatSub === 1) {
          acc.listFatSubs = {
            ...acc.listFatSubs,
            [curr.name]: {
              ...curr,
              id: curr?.name,
            },
          }
        }

        if (curr.carbohydrateSub === 1) {
          acc.listCarbohydrateSubs = {
            ...acc.listCarbohydrateSubs,
            [curr.name]: {
              ...curr,
              id: curr?.name,
            },
          }
        }

        if (curr.required === 1
            && curr.carbohydrateSub === 0
            && curr.fatSub === 0
        ) {
          acc.listMainNutritions = {
            ...acc.listMainNutritions,
            [curr.name]: curr,
          }
        }

        return acc
      }, {
        listMainNutritions: {},
        listFatSubs: {},
        listCarbohydrateSubs: {},
        listOptionalNutritions: {},
      })
    },
    mainNutritionContentSingleText() {
      return this.data?.mainNutritionContent?.map((v) => v.value).join(', ')
    },
  },
  async mounted() {
    const [
      getListOptionsBasicIngredient,
    ] = await Promise.all([
      reqMealGlossaries_getListOptionsBasicIngredient(
        this.$store.getters.axios,
      ),
    ])

    this.optionBasicIngredients = getListOptionsBasicIngredient?.data?.data?.basicFoodIngredient?.map((v) => ({
      id: v.value,
      name: v.value,
    })) || []
    this.optionGroups = getListOptionsBasicIngredient?.data?.data?.foodGroup?.map((v) => ({
      id: v.value,
      name: v.value,
    })) || []
    this.optionCuisines = getListOptionsBasicIngredient?.data?.data?.cuisine?.map((v) => ({
      id: v.value,
      name: v.value,
    })) || []
    this.listNutritions = getListOptionsBasicIngredient?.data?.data?.nutrition || []
    this.optionHouseholdMeasurement = getListOptionsBasicIngredient?.data?.data?.householdMeasurement?.map((v) => ({
      id: v.value,
      name: v.value,
    })) || []
    this.optionServingSizeUnit = getListOptionsBasicIngredient?.data?.data?.servingSizeUnit?.map((v) => ({
      id: v.value,
      name: v.value,
    })) || []
    this.optionMainNutrition = getListOptionsBasicIngredient?.data?.data?.mainNutrition?.reduce((acc, curr) => {
      acc = {
        ...acc,
        [curr.id]: {
          ...curr,
          name: curr.value,
          checked: false,
        },
      }
      return acc
    }, {}) || {}

    try {
      if (this.isEditPage) {
        const res = await reqMealGlossaries_getDetail(
          this.$store.getters.axios, {
            id: this.glossaryId,
          })
        const data = res?.data?.data
        this.data = {
          ...data,
          photoUrl: [data?.photoUrl],
          nutritionContent: {
            ...data?.nutritionContent,
            calories: {
              ...data?.nutritionContent?.calories,
              amount: data?.nutritionContent?.calories?.calories,
            },
            protein: {
              ...data?.nutritionContent?.protein,
              amount: data?.nutritionContent?.protein?.protein,
            },
            fat: {
              ...data?.nutritionContent?.fat,
              fat: {
                ...data?.nutritionContent?.fat?.fat,
                amount: data?.nutritionContent?.fat?.fat,
              },
            },
            carbohydrate: {
              ...data?.nutritionContent?.carbohydrate,
              carbohydrate: {
                ...data?.nutritionContent?.carbohydrate?.carbohydrate,
                amount: data?.nutritionContent?.carbohydrate?.carbohydrate,
              },
            },
            cholesterol: {
              ...data?.nutritionContent?.cholesterol,
              amount: data?.nutritionContent?.cholesterol?.cholesterol,
            },
            natrium: {
              ...data?.nutritionContent?.natrium,
              amount: data?.nutritionContent?.natrium?.natrium,
            },
            iron: {
              ...data?.nutritionContent?.iron,
              amount: data?.nutritionContent?.iron?.iron,
            },
          },
        }
        this.list = data?.mainNutritionContent?.map((value) => {
          return {
            ...value,
            checked: true,
          }
        })
          .sort((a, b) => a.order - b.order) || []

        this.optionMainNutrition = Object.keys(this.optionMainNutrition).reduce((acc, curr) => {
          acc = {
            ...acc,
            [curr]: {
              ...this.optionMainNutrition[curr],
              checked: this.list.some((v) => v.id === curr),
            },
          }
          return acc
        }, {})
      } else {
        this.data.servingSizePerPortion.servingSizeUnitPerPortion = this.optionServingSizeUnit[0]?.name
        this.data.householdMeasurementPerPortion.householdMeasurementUnitPerPortion = this.optionHouseholdMeasurement[0]?.name
        this.data.servingSize.servingSizeUnit = this.optionServingSizeUnit[0]?.name
        this.data.householdMeasurement.householdMeasurementUnit = this.optionHouseholdMeasurement[0]?.name
      }
      this.data.nutritionContent.calories.unit = this.parseNutrition?.listMainNutritions?.['Energi']?.unit
      this.data.nutritionContent.protein.unit = this.parseNutrition?.listMainNutritions?.['Protein']?.unit
      this.data.nutritionContent.cholesterol.unit = this.parseNutrition?.listMainNutritions?.['Kolesterol']?.unit
      this.data.nutritionContent.fat.fat.unit = this.parseNutrition?.listMainNutritions?.['Lemak']?.unit
      this.data.nutritionContent.carbohydrate.carbohydrate.unit = this.parseNutrition?.listMainNutritions?.['Karbohidrat']?.unit
      this.data.nutritionContent.natrium.unit = this.parseNutrition?.listMainNutritions?.['Natrium']?.unit
      this.data.nutritionContent.iron.unit = this.parseNutrition?.listMainNutritions?.['Zat besi']?.unit
    } catch (e) {
      this.$toast({
        status: 'error',
        title: 'Error',
        description: parseErrorCatch(e),
        duration: 3000,
      })
    }
  },
  methods: {
    parseErrors,
    isInvalidField,
    selectAllText(event, value) {
      return value <= 0 ? event.target.select() : null
    },
    cancelEditOrAdd() {
      if (this.$v.$anyDirty) {
        this.isOpenModalCancelEditOrAdd = true
      } else {
        this.$router.back()
      }
    },
    cancelChangUnit() {
      this.isOpenModalUnit = false
      this.selectedUnit = null
    },
    changeUnit() {
      if (this.isOpenModalUnit === 'servingSize') {
        this.$v.data.servingSize.servingSizeUnit.$touch()
        this.data.servingSize.servingSizeUnit = this.selectedUnit
      } else if (this.isOpenModalUnit === 'householdMeasurement') {
        this.$v.data.householdMeasurement.householdMeasurementUnit.$touch()
        this.data.householdMeasurement.householdMeasurementUnit = this.selectedUnit
      } else if (this.isOpenModalUnit === 'servingSizePerPortion') {
        this.$v.data.servingSizePerPortion.servingSizeUnitPerPortion.$touch()
        this.data.servingSizePerPortion.servingSizeUnitPerPortion = this.selectedUnit
      } else if (this.isOpenModalUnit === 'householdMeasurementPerPortion') {
        this.$v.data.householdMeasurementPerPortion.householdMeasurementUnitPerPortion.$touch()
        this.data.householdMeasurementPerPortion.householdMeasurementUnitPerPortion = this.selectedUnit
      }
      this.isOpenModalUnit = false
      this.selectedUnit = null
    },
    cancelChangeMainNutrition() {
      this.$v.data.mainNutritionContent.$touch()
      this.isOpenModalZatGiziDominan = false
      this.list = this.data?.mainNutritionContent?.map((value) => {
        return {
          ...value,
          checked: true,
        }
      })
        .sort((a, b) => a.order - b.order) || []

      this.optionMainNutrition = Object.keys(this.optionMainNutrition).reduce((acc, curr) => {
        acc = {
          ...acc,
          [curr]: {
            ...this.optionMainNutrition[curr],
            checked: this.list.some((v) => v.id === curr),
          },
        }
        return acc
      }, {})
    },
    changeMainNutrition() {
      this.$v.data.mainNutritionContent.$touch()
      this.data.mainNutritionContent = this.list?.reduce((acc, curr) => {
        if (curr.checked) {
          acc.push({
            ...curr,
            order: acc.length + 1,
          })
        }
        return acc
      }, [])
      this.isOpenModalZatGiziDominan = false
    },
    checkedMainNutrition(isChecked, checkedItem) {
      this.optionMainNutrition[checkedItem].checked = isChecked
      if (isChecked) {
        this.list.push(this.optionMainNutrition[checkedItem])
      } else {
        this.list = this.list.filter((v) => v.id !== checkedItem)
      }
    },
    async onSubmit() {
      // check form validity
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.isSubmitting = true

      // upload photos
      let arrPromiseImageCompress = []
      let arrPromiseUploadPhotos = []

      for (let photo of this.data?.photoUrl || []) {
        if (isDataEmpty(photo)) {
          arrPromiseUploadPhotos.push(photo)
          continue
        }
        if (typeof photo === 'string') {
          arrPromiseUploadPhotos.push(photo)
          continue
        }
        let options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }
        arrPromiseImageCompress.push(imageCompression(photo.file, options))
      }

      const compressedPhotos = await Promise.all(arrPromiseImageCompress)
      for (let compressedPhoto of compressedPhotos) {
        let formData = new FormData()
        let fileName = compressedPhoto.name
        let file = new File([compressedPhoto], fileName)
        formData.append('file', file, fileName)
        let url = reqReferral.uploadFile(
          this.$store.getters.axios,
          formData,
        ).then((r) => r.data.data?.url)
          .catch((e) => {
            this.$toast({
              status: 'error',
              title: 'Error',
              description: parseErrorCatch(e),
              duration: 3000,
            })
          })
        arrPromiseUploadPhotos.push(url)
      }

      const res = await Promise.all(arrPromiseUploadPhotos)
      const resFilterd = res?.reduce((acc, curr) => {
        if (typeof curr === 'string') {
          acc.push(curr)
        }
        return acc
      }, [])

      arrPromiseImageCompress = []
      arrPromiseUploadPhotos = []

      let payload = {
        ...this.data,
        foodIngredient: this.data?.foodGroup?.toUpperCase() === 'ONE-DISH MEAL' ? this.data.foodIngredient : '',
        photoUrl: resFilterd[0],
        mainNutritionContent: this.data?.mainNutritionContent?.map((v) => {
          if (v._new) {
            return {
              order: v.order,
              value: v.value,
            }
          } else {
            return {
              id: v.id,
              order: v.order,
              value: v.value,
              photoUrl: v.photoUrl,
            }
          }
        }),
        isNewMainNutrition: this.data?.mainNutritionContent?.some((v) => v._new) ? '1' : '0',
      }

      if (this.isEditPage) {
        // edit gift
        reqMealGlossaries_edit(
          this.$store.getters.axios, {
            id: this.glossaryId,
          }, payload).then(() => {
          this.isSubmitting = false
          this.isOpenModalConfirmationEditSchema = false
          this.isOpenModalSuccessEditOrAdd = true
        }).catch((e) => {
          this.$toast({
            status: 'error',
            title: 'Error',
            description: parseErrorCatch(e),
            duration: 3000,
          })
        })
      } else {
        // create gift
        reqMealGlossaries_create(
          this.$store.getters.axios, payload).then(() => {
          this.isOpenModalConfirmationEditSchema = false
          this.isOpenModalSuccessEditOrAdd = true
        }).catch((e) => {
          this.$toast({
            status: 'error',
            title: 'Error',
            description: parseErrorCatch(e),
            duration: 3000,
          })
        }).finally(() => {
          this.isSubmitting = false
        })
      }
    },
  },
  metaInfo: {
    title: 'Nilai Gizi | Dietela',
  },
  validations() {
    return {
      data: {
        photoUrl: {
          required: (v) => {
            if (typeof v === 'string') {
              return !isDataEmpty(v)
            }
            const filtered = v?.reduce((acc, curr) => {
              if (!isDataEmpty(curr)) {
                acc.push(curr)
              }
              return acc
            }, [])
            return !isDataEmpty(filtered)
          },
        },
        foodName: {
          required,
        },
        foodGroup: {
          required,
        },
        basicFoodIngredient: {
          required,
        },
        foodIngredient: {
          required: requiredIf(() => this.foodGroup?.toUpperCase() === 'ONE-DISH MEAL'),
        },
        servingSizePerPortion: {
          servingSizePerPortion: {
            required,
            minValue: minValue(1),
          },
          servingSizeUnitPerPortion: {
            required,
          },
        },
        householdMeasurementPerPortion: {
          householdMeasurementPerPortion: {
            required,
            minValue: minValue(1),
          },
          householdMeasurementUnitPerPortion: {
            required,
          },
        },
        servingSize: {
          servingSize: {
            required,
            minValue: minValue(1),
          },
          servingSizeUnit: {
            required,
          },
        },
        householdMeasurement: {
          householdMeasurement: {
            required,
            minValue: minValue(1),
          },
          householdMeasurementUnit: {
            required,
          },
        },
        nutritionContent: {
          calories: {
            amount: {
              required,
              minValue: minValue(0),
            },
          },
          protein: {
            amount: {
              required,
              minValue: minValue(0),
            },
          },
          fat: {
            fat: {
              amount: {
                required,
                minValue: minValue(0),
              },
            },
            sub: {
              $each: {
                name: {
                  required,
                },
                amount: {
                  required,
                  minValue: minValue(0),
                },
              },
            },
          },
          carbohydrate: {
            carbohydrate: {
              amount: {
                required,
                minValue: minValue(0),
              },
            },
            sub: {
              $each: {
                name: {
                  required,
                },
                amount: {
                  required,
                  minValue: minValue(0),
                },
              },
            },
          },
          cholesterol: {
            amount: {
              required,
              minValue: minValue(0),
            },
          },
          natrium: {
            amount: {
              required,
              minValue: minValue(0),
            },
          },
          iron: {
            amount: {
              required,
              minValue: minValue(0),
            },
          },
          additonalNutritionContent: {
            $each: {
              name: {
                required,
              },
              amount: {
                required,
                minValue: minValue(0),
              },
            },
          },
        },
        mainNutritionContent: {
          required,
        },
      },
    }
  },
}
</script>
